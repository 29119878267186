import React from 'react'

import { Layout, OpeningSection, StackSection } from 'organisms'

const IndexPage = () => (
	<Layout>
		<OpeningSection />
		<StackSection />
	</Layout>
)

export default IndexPage
